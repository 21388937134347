<template>
  <div class="end">
    <div class="h1">LB 活动平台</div>
    <div class="box1">
      <p><img src="@/assets/image/end.png" width="60%"/></p>
      <p>亲，太遗憾啦 ~ 本轮活动已结束，<br/>敬请期待下次精彩</p>
    </div>
    <div class="box2">
      <p>技术支持：</p>
      <p>客 服 QQ：39927556</p>
      <p>客服手机/微信：13301481520</p>
      <!--<p class="tc"><img src="/web/style/images/LB_qrcode.jpg"></p>
			<p class="tc">扫一扫或长按此二维码，加客服微信</p>-->
    </div>
    <div class="box3">
      <p class="more">了解更多活动，详情见 》 <a href="http://www.kjlbao.cn">官网</a></p>
      <p><img src="@/assets/image/logo_gray.png" width="80"/></p>
    </div>
    <global/>
  </div>
</template>

<script>
  import global from './wx/components/global'
export default {
  name:'finish',
  components: {global},
  data() {
    return {
    }
  },
  
  mounted() {
  
  },
  methods: {
  
  }
}
</script>
<style lang="less" scoped>
  .end{height:auto;color:#666;
    img{max-width:100%;}
    .h1{background:#00b0ec;font-size:22px;text-align:center;color:#ffffff;line-height:60px;}
    .h5{font-size:20px;}

    .box1{height:auto;padding:15px;text-align:center;font-size:18px;line-height:30px;}
    .box2{height:auto;padding:15px;line-height:30px;}
    .box3{height:auto;padding:15px;text-align:center;}
  }
  
</style>
